
.qui-sommes-nous {
    h2, h3 {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .phrase_entetes {
		background-color: rgba($color_vert, 0.2);
	}

    #entete_page {
        .col_gauche {
            padding-top: $fullwidth_padding_sides;

            h2 {
                font-weight: 400;
            }

            h2:nth-child(2) {
                font-size: 1.8rem;
                font-weight: 700;
                margin-bottom: 20px;
            }
        }
        .col_droite {
            padding: $fullwidth_padding_sides;
            padding-top: 0;

            img {
                margin-bottom: 5px;
                height: auto;
                max-height: 440px;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down($responsive_breakpoint) {
            margin-top: 0;
        }
    }

    section#contenu_centre {
        img {
            width: 100%;
        }

        @include media-breakpoint-down($responsive_breakpoint) {
            .col_gauche {
                margin-bottom: 30px;
            }
        }
    }

    section#pleine_largeur {
        margin-bottom: calc($fullwidth_padding_sides + 40px);

        .overlay {
            position: absolute;
            left: $fullwidth_padding_sides;
            bottom: -$fullwidth_padding_sides;
            padding: 30px;
            background-color: $color_vert;
            max-width: calc(50% - ($fullwidth_padding_sides));

            @include media-breakpoint-down($responsive_breakpoint) {
                position: relative;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                max-width: 100%;
            }
        }

        img {
            width: 100%;
        }
    }

    section#historique {
        margin: 100px 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .zone {
            overflow: hidden;
            transition: 0.5s;
        }

        .grostitre {
            margin-bottom: 40px;
        }

        .bloc_historique {
            margin-bottom: 40px;

            .date {
                font-size: 3rem;
                font-weight: 700;
                margin: 0;
            }

            .contenu {
                display: flex;
                align-items: center;

                .trait {
                    height: 100%;
                    min-height: 100px;
                    width: 1px;
                    margin: 0 10px;
                    background-color: $color_black;
                    align-self: stretch;
                }

                .texte {
                    padding: 16px 0;
                    line-height: 1.2;
                }
            }
        }
    }

    section#entete_engagements {
        margin-bottom: 60px;
    }

    .module_nous_contacter {
        .col_gauche{
            background-color: rgba($color_vert, 0.2);
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 16px;
        }
    }
}