
.contact, .contact-tarifs {

	.error {
		color: red;
	}

	#titre_page {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.surtitre {
			display: none;
		}
	}

	#contenu_page {
		margin-top: 40px;
		margin-bottom: 80px;

		.titre_center {
			font-size: 1.1rem;
		}

		.gauche {
			display:flex;
			flex-direction: column;
			//justify-content: space-between;

			.tel_haut {
				font-size: calc(0.4rem + 1.6vw);
				font-weight: 800;
				color: $color_black;
				text-decoration: none;
			}

			.bloc_carte {
				margin-top: 220px;
			}

			.spacer {
				flex: 1;
			}

			.bloc_adresse {
				padding-bottom: 10px;
				border-bottom: 1px solid $color_black;

				p {
					margin: 0;
					line-height: 1.3rem;
				}

				.titre {

				}

				.adresse {
					
				}

				.telephone {
					a {
						color: $color_black;
						text-decoration: none;
					}
				}

				.fax {
					
				}
			}

			
		}

		.droite {

			form {
				.input_container {

					.form-input {
						margin-bottom: 16px;
					}
					.form-input:nth-child(odd) { padding-right: 12px; }
					.form-input:nth-child(even) { padding-left: 12px; }

					label, select {
						display: block;

						.star {
							color: $color_orange;
						}
					}

					.form-input, .form-input-message {
						input, textarea {
							background-color: $color_grisclair;
							border: none;
							width: 100%;
							padding: 6px;
						}
					}

					.form-input-message {
						margin-top: 20px;
					}

					select {
						width: 100%;
						height: 36px;
						background-color: $color_grisclair;
						border: none;

						option {
							background-color: $color_grisclair;
						}
					}

					.checkbox_container {
						//justify-content: space-between;

						.bloc {
							margin-right: 16px;
							input {
								display: inline-block;
								width: 20px;
							}

							span {
								display: inline-block;
								//width: 36px;
								white-space: nowrap;
							}

							label {
								display: inline-block;
							}
						}

						.bloc._3 {
							//margin-right: 30px;
						}
						
					}

					.form-input-checkboxes {
						margin: 20px 0;

						.bloc {
							margin-bottom: 10px;
							input {
								display: inline-block;
								width: 20px;
								background: $color_grisclair;
							}
						}
					}

					.form-input-submit {
						text-align: right;

						input {
							cursor: pointer;
							padding: 0.4rem 6rem;
						}
					}
				}

				.input_container._1 {
					
				}

				.titre_center {
					margin: 30px 0;
				}

				.input_container._2 {
					.form-input:nth-child(odd) { padding-right: 20px; }
					.form-input:nth-child(even) { padding-left: 20px; }
				}

				.ui-slider {
					display: block;
					margin-top: 16px;
					height: 2px;
					background: none;
					border: none;
					background-color: $color_grisfonce;

    				.ui-slider-handle {
    					cursor: pointer;
    					background: none;
    					background-color: $color_orange;
    					border-radius: 100px;
    					top: 0;
    					transform: translateY(-50%);
    					border: none;
    					box-shadow: 2px 2px 4px rgba($color_black, 0.2);
    				}

    				.ui-slider-pip {
    					top: -3px;

    					.ui-slider-line {
    						height: 9px;
    						width: 3px;
    						background-color: $color_grisfonce;
    					}

    					.ui-slider-label {
    						top: 14px;
    						color: $color_black;
    						font-weight: 500;
    						transition: 0.1s;
    					}
    				}

    				.ui-slider-pip-selected {
    					.ui-slider-label {
    						font-weight: 700;
    					}
    				}

    				.ui-slider-pip-last {
    					.ui-slider-label:after {
    						content: ' ou +';
    						display: inline-block;
    					}
    				}
				}

				.bulle {
					position: relative;
					display: block;
					margin: auto;
					margin-top: 40px;
					width: 50%;
					text-align: center;
					background-color: $color_grisfonce;
					color: $color_white;
					font-weight: 700;
					border-radius: 100px;

					.fleche {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translate(-50%, -100%);
						height: 20px;
						width: 20px;
						border: 10px solid transparent;
						border-bottom: 10px solid $color_grisfonce;
					}
				}

				.slider_container {
					/* $bg: #434d5a;

					[id*=flat-slider].ui-slider,
					[id*=flat-slider].ui-slider .ui-slider-pip .ui-slider-line {
					    background: lighten($bg, 25%);
					}

					[id*=flat-slider].ui-slider .ui-slider-handle .ui-slider-tip:after {
					    border-left-color: #434d5a;
					}


					[id*=flat-slider].ui-slider .ui-slider-handle.ui-state-hover, 
					[id*=flat-slider].ui-slider .ui-slider-handle.ui-state-focus, 
					[id*=flat-slider].ui-slider .ui-slider-handle.ui-state-active {
					      border-color: white; } */

				}
			}

		}
	}

	#carte_googlemaps {
		height: 45vw;
	}
	
	.terrain_bloc {
		margin-bottom: 40px !important;
	}

	.infos_terrain {
		font-size: 1.3rem;
		margin-bottom: 30px;
	}

	.dep_bloc {
		.inputs_container {
			position: relative;

			.loader {
				display: none;
				position: absolute;
				left: -10px;
				top: 40%;
				transform: translate(-100%, -50%);
				height: 40px;
				width: 40px;
				opacity: 0.5;
			}

			// Animation loader
			.lds-ring {
				display: inline-block;
				position: relative;
				width: 80px;
				height: 80px;
			}
			.lds-ring div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: 32px;
				height: 32px;
				margin: 8px;
				border: 5px solid #000;
				border-radius: 50%;
				animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				border-color: #000 transparent transparent transparent;
			}
			.lds-ring div:nth-child(1) {
				animation-delay: -0.45s;
			}
			.lds-ring div:nth-child(2) {
				animation-delay: -0.3s;
			}
			.lds-ring div:nth-child(3) {
				animation-delay: -0.15s;
			}
			@keyframes lds-ring {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
			// /animation loader
		}

		.liste_deps {
			margin-bottom: 20px !important;
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {

		.tel_haut {
			font-size: 1.8rem !important;
		}

		.terrain_bloc {
			margin-bottom: 16px !important;
		}

		#contenu_page {
			margin-left: 0;
			margin-right: 0;

			.gauche, .droite {
				padding: 0;
			}

			> .gauche {
				margin-bottom: 50px;
			}

			.form-input {
				padding: 0 !important;
			}

			.bloc_carte {
				display: none;
			}

			.liste_deps {
				display: block;
				margin-bottom: 40px !important;
			}

			.checkbox_container {
				justify-content: left !important;

				.bloc._1 {
					margin-right: 10px;
				}
			}
		}

		#carte_googlemaps {
			height: 100vw;
		}

		.site-main {
		    padding: 0;
		}

		

		section .content {
			width: 100% !important;
		}
		
	}

}

.recaptcha_container {
	margin-bottom: 10px;

	.g-recaptcha {
		> div {
			margin-left: auto;
		}
	}
}