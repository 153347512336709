
// ----------------------------------
// ||            Navbar            ||
// ----------------------------------

// ----------------- CSS SPECIFIQUE AU SITE -----------------

/* body.admin-bar {
	.permanent_nav, .navbar-toggler {
		top: 32px !important;
	}
	#masthead .navbar-collapse {
	    padding: 92px 20px 20px;
	}
} */

.logo_resp {
	display: none;
	position: fixed;
	z-index: 50;
	width: 20vw !important;
	min-width: 50px;
	max-width: 60px;
	margin: auto;
	margin-bottom: 20px;
	top: 5px;
	left: 5px;

	pointer-events: all;
	opacity: 1;
	transition: 0.5s;

	img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.logo_resp.scroll-level-reached {
	pointer-events: none;
	opacity: 0;
}

.full_site {
	padding: 0 !important;
}

.img_resp {
	pointer-events: all;
	display: none;
	position: fixed;
	z-index: 50;
	width: 10vw !important;
	min-width: 50px;
	max-width: 60px;
	padding: 0;
	margin: 0 !important;
	margin-bottom: 20px !important;
	top: 5px;
	left: 5px;
}
.img_resp.in-view {
	pointer-events: none;
	opacity: 0;
}

.navbar_container {	
	z-index: 10000000;

	.navbar {
		position: relative;

		@include media-breakpoint-down($responsive_breakpoint) {
			height: 70px;
			width: 100vw;
			background-color: $color_white !important;
		}

		.navbar-toggler {
			top: 20px;
			right: 5px;
		}
	}

	.navbar-collapse {
		z-index: 10000000;

		.tel_nav {
			display: block;
			color: $color_white;
			font-size: 2.1rem;
			line-height: 2.1rem;
			font-weight: 700;
			margin-bottom: 6px;
		}
		.tel_nav:hover {
			text-decoration: none;
		}

		.col_gauche {
			.etage {
				display: flex;
				align-items: flex-start;
				position: relative;

				@include media-breakpoint-down($responsive_breakpoint) {
					flex-direction: column;
					align-items: center;
				}

				.btn_left_search {
					position: absolute;
					left: -30px;
					top: 0;
					transform: translateX(-100%);
					font-size: 0.95rem;
					font-weight: 800;
					padding: 0.3rem 1.5rem;
					padding-right: 4rem;

					@include media-breakpoint-down($responsive_breakpoint) {
						position: relative;
						transform: none;
						left: 0;
						margin-bottom: 20px;
					}
				}
			}
		}

		#nav_search_form {
			display: flex;
			background-color: $color_white;
			margin-bottom: 30px;
			min-width: 300px;
			border-radius: 1000px;

			* {
				display: inline-block;
				margin: 0;
			}

			input {
				order: 1;
				appearance: none;
				border: none;
				padding: 4px 15px;
				background-color: transparent;
				width: 100%;
			}

			input::placeholder {
				font-size: 0.9rem;
				font-style: italic;
			}

			.search_button {
				order: 2;
				cursor: pointer;
				border-radius: 0;
				background-color: transparent;
				/* padding: 0.19rem 0.375rem; */
				padding: 0.25rem 0.4rem;
			}
		}

		.logo_madeinjura {
			width: 45px;
			margin-left: 16px;
			transform: translateY(-5px);

			@include media-breakpoint-down($responsive_breakpoint) {
				display: none;
				// position: absolute;
				// top: -10px;
				// left: -10px;
				// width: 45px !important;
				// margin: 0;
				// transform: none;
			}
		}

		.fb_link {

			a {
				padding: 0 !important;
			}

			i {
				font-size: 1.3rem;
			}
		}

		.col_gauche {
			width: 100%;
			justify-content: flex-end;
			/* transform: translateY(-12px); */
		}

		.col_centrale {
			justify-content: flex-end;
			transform: translateY(8px);
		}

		.dropdown-menu {
			/* overflow: hidden; */
			transform: translateY(0px);
			padding-bottom: 30px;

			a {
				border-left: 1px solid $color_black;
				font-weight: 700
			}

			.spacer_haut {
				width: 50px;
				height: 4px;
				margin-left: 4px;
				margin-bottom: 20px;
				//background-color: $color_orange;
				background-color: transparent;
			}

			.dropdown_text {
				margin-left: auto;
				margin-right: 20px;
				margin-top: 20px;
				max-width: 300px;
				font-weight: 800;
				color: $color_gris;
			}

			.fill {
				position: absolute;
				z-index: 100;
				top: 0;
				height: 100%;
				width: 100vw;
				background-color: $color_white;
			}
			.fill.left {
				left: 0;
				transform: translateX(-100%);
			}
			.fill.right {
				right: 0;
				transform: translateX(100%);
			}

			.fill_top {
				position: absolute;
				z-index: 100;
				top: 0;
				width: 100%;
				height: 20px;
				transform: translateY(-100%);
			}

			.dropdown_text {
				text-align: right;
			}
		}
		.dropdown-menu.show {
			overflow: visible;
			transform: translateY(12px);
		}

	}
}



@include media-breakpoint-down($responsive_breakpoint) {
	
	.img_resp {
		display: block;
	}
	.navbar_container {
		position: fixed;

		.navbar-collapse {

			#nav_search_form {
				text-align: left;
				margin: 0;
				left: 0;
			}

			.container {
				display: block !important;
			}

			.logo_resp {
				display: block;
				width: 20vw !important;
				min-width: 60px;
				max-width: 150px;
				margin: auto;
				margin-bottom: 20px;
			}

			.tel_nav {
				text-align: center;
			}

			.etage {
				text-align: center;
			}

			ul.navbar-nav {

				a {
					line-height: 1rem;
					font-weight: 700 !important;
				}

				a:focus, a:active, a:hover {
					background-color: transparent !important;
				}

				.nav-item {
					margin: 0;
					margin-bottom: 8px;
				}

				.dropdown .dropdown-menu {
					overflow: hidden;
					opacity: 1;
					transition: 0.4s;
					padding: 0;
					margin-left: 30px;
					transform: none;
					max-height: 0;
					padding-bottom: 0px;

					.spacer_haut { display: none; }
					.fill { display: none; }

					a {
						color: $color_white;
						border-left: 1px solid $color_white;
						font-weight: 500 !important;
						padding-top: 8px;
					}
				}
				.dropdown.show .dropdown-menu {
					max-height: 300px;
					padding-bottom: 10px;
				}
			}

		}

	}
}

@include media-breakpoint-down(xs) {
	.navbar_container {
		.navbar-collapse {
			#nav_search_form {
				//margin-left: 30px;
			}
		}
	}
}

.logo_container_resp {
	display: block;

	* {
		position: relative !important;
		margin: 0 !important;
	}
}


// ----------------------------------------------------------

// NAVBAR - GÉNÉRAL
.navbar {
	z-index: 1000;
	width: $navbar_width;
	background-color: $navbar_bgcolor;
	transition: 0.5s;

	@if $navbar_bggradient != 0 {
		background: $navbar_bggradient;
	}
	box-shadow: 0px 0px $navbar_shadow_height rgba(0, 0, 0, $navbar_shadow_opacity);
	@if $navbar_position == fixed_top {
		position: fixed;
		top: 0;
	}
	@if $navbar_position == fixed_bottom {
		position: fixed;
		bottom: 0;
	}

	.container {
		@if $navbar_contained == 0 { 
			max-width: none !important; 
			padding: 0;
		}
	}

	.puller {
		@if $navbar_toggler_position == 'left' {
			display: none;
		}
	}

	.navbar_collapsed_header { display: none; }
}
// .navbar:hover {
// 	background-color: rgba($color_white, 1);

// 	.nav-link {
// 		color: $color_black !important;
// 	}
// }


// NAVBAR - BRAND
.navbar-brand {
	height: $navbar_brand_height;

	@if $navbar_brand_vertical_position == top { align-self: flex-start; }
	@if $navbar_brand_vertical_position == bottom { align-self: flex-end; }

	img {
		height: 100%;
		width: auto;
	}
}


// NAVBAR - LIENS
@if $navbar_links_align == right {
	.nav-item:first-child {
		margin-left: auto;
	}
}
@if $navbar_links_align == center {
	.nav-item:first-child, .nav-item.icon:first-child {
		margin-left: auto !important;
	}
	.nav-item:last-child, .nav-item.icon:last-child {
		margin-right: auto !important;
	}
}
.nav-item {
	position: relative;
	margin: $navbar_links_margin;

	.nav-link {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		font-size: $navbar_links_fontsize;
		line-height: $navbar_links_fontsize;
		font-weight: $navbar_links_fontweight;
		color: $navbar_links_color;
		background-color: $navbar_links_bgcolor;
		border-radius: $navbar_links_border_radius;
		padding: $navbar_links_padding-y $navbar_links_padding-x !important;
		transition: $navbar_links_animtime;
		@if $navbar_links_forceuppercase == 1 {
			text-transform: uppercase;
		}
		@if $navbar_dropdown_animation_type == slide_fixed_title {
			z-index: 10000 !important;
		}

		@media screen and (min-width: 1900px) {
			font-size: 1.1rem;
		}
	}

	@if $navbar_underline_use == 1 {
		.nav-underline {
			position: absolute;
			bottom: -14px;
			background-color: $navbar_underline_color;
			transition: $navbar_underline_animtime;

			@if $navbar_underline_popdirection == 'left' {
				height: $navbar_underline_height;
				width: 0;
				left: 0;
			}

			@if $navbar_underline_popdirection == 'right' {
				height: $navbar_underline_height;
				width: 0;
				right: 0;
			}

			@if $navbar_underline_popdirection == 'center' {
				height: $navbar_underline_height;
				width: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			@if $navbar_underline_popdirection == 'bottom' {
				height: 0;
				width: $navbar_underline_width;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	@else { .nav-underline { display: none; } }

}


// NAVBAR - LIENS HOVER
.nav-item:hover, .nav-item:active, .nav-item.active, .nav-item.show {

	.nav-link {
		color: $navbar_links_color_hover;
		background-color: $navbar_links_bgcolor_hover;
		border-radius: $navbar_links_border_radius_hover;
	}

	@if $navbar_underline_use == 1 {
		.nav-underline {

			@if $navbar_underline_popdirection == 'left' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'right' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'center' {
				width: $navbar_underline_width;
			}

			@if $navbar_underline_popdirection == 'bottom' {
				height: $navbar_underline_height;
			}

		}
	}

}

// NAVBAR - ICONES
.nav-item.icon {
	margin: 0 $navbar_icon_margin !important;


	.nav-link {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	.nav-link:hover {
		background-color: transparent !important;
	}

	.nav_icon_container {
		border: $navbar_iconcontainer_border;
		border-radius: $navbar_iconcontainer_borderradius;
		padding: $navbar_iconcontainer_padding;
		background-color: $navbar_iconcontainer_bgcolor;
		height: $navbar_icon_border_size;
		width: $navbar_icon_border_size;
		transition: $navbar_iconcontainer_animtime;

		i {
			@extend %absolutecenter_xy;
			color: $navbar_links_color;
			transition: $navbar_links_animtime;
			font-size: $navbar_icon_size;
		}
	}
}

.nav-item:hover {

	.nav_icon_container {
		border: $navbar_iconcontainer_border_hover;
		border-radius: $navbar_iconcontainer_borderradius_hover;
		background-color: $navbar_iconcontainer_bgcolor_hover;
	}

	i {
		color: $navbar_links_color_hover;
	}
}


// NAVBAR - DROPDOWNS
.nav-item .dropdown-menu {
	padding: $navbar_dropdown_padding-y $navbar_dropdown_padding-x;
	background-color: $navbar_dropdown_bgcolor;
	border-radius: $navbar_dropdown_borderradius !important;
	border: $navbar_dropdown_bordersize solid $navbar_dropdown_bordercolor;
	box-shadow: $navbar_dropdown_shadow !important;
	@if $navbar_dropdown_position == below {
		top: 100%;
	}
	@if $navbar_dropdown_position == above {
		top: 0;
	}

	@if $navbar_dropdown_animation_type == slide_fixed_title {
		top: 0;
		left: calc(-#{$navbar_dropdown_links_padding-x} - #{$navbar_dropdown_padding-x} + #{$navbar_links_padding-x});
	}

	a { 
		color: $navbar_dropdown_link_color;
		@if $navbar_dropdown_links_forceuppercase == 1 {
			text-transform: uppercase;
		} @else {
			text-transform: none;
		}
		font-size: $navbar_links_fontsize;
		padding: $navbar_dropdown_links_padding-y $navbar_dropdown_links_padding-x;
		text-align: $navbar_dropdown_links_align;
	}

	a:hover {
		color: $navbar_dropdown_link_colorhover;
		background-color: $navbar_dropdown_links_bghover;
	}

	@if $navbar_dropdown_animation_type != none {
		transition: $navbar_dropdown_animation_time;
		display: block !important;
	}

	@if $navbar_dropdown_animation_type == unfold {
		overflow: hidden;
		padding: 0;
		max-height: 0;
	}

	@if $navbar_dropdown_animation_type == opacify {
		opacity: 0;
		pointer-events: none;
	}

	@if $navbar_dropdown_animation_type == slide OR $navbar_dropdown_animation_type == slide_fixed_title {
		opacity: 0;
		pointer-events: none;
		transform: translateY(50px);
	}

	@if $navbar_dropdown_animation_type == slide_fixed_title {
		padding-top: 60px;
	}
}

.nav-item .dropdown-menu.show {
	@if $navbar_dropdown_animation_type == none {
		display: block;
	}

	@if $navbar_dropdown_animation_type == unfold {
		padding: $navbar_dropdown_padding-y $navbar_dropdown_padding-x;
		max-height: 50vh;
	}

	@if $navbar_dropdown_animation_type == opacify {
		opacity: 1;
		pointer-events: auto;
	}

	@if $navbar_dropdown_animation_type == slide OR $navbar_dropdown_animation_type == slide_fixed_title {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(-10px);
	}
}




// NAVBAR - TOGGLER

// Gestion angle si toggler trop allongé
$togglerbar_angle: 45deg;
/* $navbar_toggler_ratio: $navbar_togglericon_height / $navbar_togglericon_width;
@if $navbar_toggler_ratio < 0.5 { $togglerbar_angle: 25deg; } */

.navbar-toggler {
	z-index: 100000000;
	cursor: pointer;
	background-color: $navbar_toggler_bgcolor;
	font-size: $navbar_togglericon_size;
	border: $navbar_toggler_border;
	border-radius: $navbar_toggler_borderradius;

	.navbar-toggler-icon {
		position: relative;
		width: $navbar_togglericon_width;

		.toggler-bar {
			position: absolute;
			width: 100%;
			left: 0;
			height: $navbar_togglerbar_height;
			background-color: $navbar_togglerbar_color;
			transform-origin: center center;
			transform: translateY(-50%);
			transition: $navbar_toggler_animtime;
		}
		// Barres quand le menu est ouvert
		.top-bar {
			top: 50%;
			transform: translateY(-50%) rotate($togglerbar_angle);
		}
		.middle-bar {
			top: 50%;
			opacity: 0;
			transition: $navbar_toggler_animtime;
		}
		.bottom-bar {
			top: 50%;
			transform: translateY(-50%) rotate(-$togglerbar_angle);
		}
	}
}

@if $navbar_toggler_animation == 1 {
	.navbar-toggler.collapsed {
		transform: translateY(0%);
		.navbar-toggler-icon {
			// Barres quand le menu est fermé
			.top-bar {
				top: 0;
				transform: translateY(0%) rotate(0deg);
			}
			.middle-bar {
				opacity: 1;
				transition: $navbar_toggler_animtime;
			}
			.bottom-bar {
				top: 100%;
				transform: translateY(-100%) rotate(0deg);
			}
		}
	}	
}


// NAVBAR - COMPORTEMENT RESPONSIVE

@include media-breakpoint-down($navbar_collapse_breakpoint) {
	
	.navbar {
		padding: 0;

		.navbar-brand {
			position: relative;
			height: 60px;
			top: 0;
			left: 0;
		}

		.puller { display: none; }

		/* .nav-item:first-child {
			margin: $navbar_links_margin;
		}
		.nav-item:last-child {
			margin: $navbar_links_margin;
		} */

		@if $navbar_collapse_keep_underline == 0 {
			.nav-underline {
				display: none;
			}
		}

		@if $navbar_collapse_direction == horizontal {
		
			.navbar-collapse { 
				height: $navbar_collapse_height !important;
				width: $navbar_collapse_width !important;
				transform: translateX(100%);
				/* width: $navbar_collapse_width; */

				@if $navbar_collapse_position == right {
					left: auto;
					right: 0; 
				}
			}

			.navbar-collapse.show {
				transform: translateX(0%) !important;
				transition: 0.5s;
			}

		}

		.navbar-collapse {
			@if $navbar_collapse_bgcolor != keep { background-color: $navbar_collapse_bgcolor; }
			@else { background-color: $navbar_bgcolor; }
			width: $navbar_collapse_width;
			transition: 0.5s;
			transition-delay: 0;

			/* .filler {
				position: absolute;
				pointer-events: none;
				height: 100vh;
				width: 100%;
			} */

			.container {
				display: flex;
				height: $navbar_collapse_height;
			
				/* width: 100%; */
				@if $navbar_collapse_content_horizontal_align == left { justify-content: flex-start; }
				@if $navbar_collapse_content_horizontal_align == center { justify-content: center; }
				@if $navbar_collapse_content_horizontal_align == right { justify-content: flex-end; }

				.col {
					padding: $navbar_collapse_col_padding;
					flex-grow: initial;
					@if $navbar_collapse_content_vertical_align == top { align-self: flex-start; }
					@if $navbar_collapse_content_vertical_align == center { align-self: center; }
					@if $navbar_collapse_content_vertical_align == bottom { align-self: flex-end; }

					.etage {
						padding: $navbar_collapse_etage_padding;
						width: 100%;
						flex-grow: initial;

						img {
							width: 100%;
							height: auto;
						}
					}
				}

				.navbar-nav {
					display: inline-block;
					/* @if $navbar_collapse_vertical_align == top { align-self: flex-start; }
					@if $navbar_collapse_vertical_align == bottom { align-self: flex-end; } */
					text-align: $navbar_collapse_links_centering;

					a {
						font-size: $navbar_collapse_links_fontsize !important;
						font-weight: $navbar_collapse_links_fontweight;
					}

					.nav-item.icon {
						display: inline-block;
					}


					.nav-link {
						@if $navbar_collapse_links_color != keep { color: $navbar_collapse_links_color; }
						transform: none;
					}
					.nav-link .nav_icon_container {
						@if $navbar_collapse_links_color != keep { border-color: $navbar_collapse_links_color; }
						i {
							@if $navbar_collapse_links_color != keep { color: $navbar_collapse_links_color; }
						}
					}
					
				}
			}
		}	
	}


	// Passage en mode nobar
	@if $navbar_collapse_nobar == 1 {
		.navbar {
			padding: 0;
			background-color: transparent;
		}

		.navbar-toggler {
			position: fixed;
			top: $navbar_toggler_distance;
			@if $navbar_toggler_position == left { left: $navbar_toggler_distance; }
			@if $navbar_toggler_position == right { right: $navbar_toggler_distance; }

		}

		.navbar-collapse {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	// Gestion dropdown en responsive
	.nav-item .dropdown-menu {
		position: relative;
		transition: none;
		display: block;
		opacity: 1;
		transform: none;
		padding-top: 0;
		background: none;
		background-color: none;
		@if $navbar_collapse_links_centering == left { padding-left: $navbar_collapse_dropdown_decalage; }
		@if $navbar_collapse_links_centering == right { padding-right: $navbar_collapse_dropdown_decalage; }
	}

}





