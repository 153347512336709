
.constructions-collectives {
	.zone_texte {
		.surtitre {
			display: none;
		}
	}
}

// .realisations.no_parent, .poteaux-poutres.no_parent, .ossature-bois.no_parent {
// 	#entete_page { display: none; }
// }

.realisations, .poteaux-poutres, .ossature-bois, .terrains.no_parent, .parent_9 {

	.phrase_entetes {
		background-color: rgba($color_vert, 0.2);
	}

	#titre_page {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: rgba($color_vert, 0.2);
	}

	&.single {
		.phrase_entetes, #titre_page {
			background-color: transparent;
		}
	}

	.documentation {


		.gauche {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			flex: 1;
		}

		.droite {

		}

		a {
			margin-right: 14px;
		}
		img {
			width: 70px;
			transform: translateY(20px);
		}
	}

	.bg_image {
		position: absolute;
		z-index: -1;
		left: 0;
		bottom: 0;
		width: 85%;
		height: 70%;
		//background-color: red;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

	.separateur {
		width: 1px;
		margin: 0 25px; 
		background-color: $color_black;
	}

	#texte_droite {
		flex: 1;
		text-align: center;

		p {
			margin: 0;
			text-align: left;
		}

		.btn {
			margin: 20px 0;
		}

	}


	#liste_realisations, #liste_terrains {
		margin: 70px 0;

		ul {
			list-style: none;
			padding: 0;
		}

		.zone {
			overflow: hidden;
			transition: 0.5s;
		}

		.realisation_item, .terrain_item {
			margin-bottom: 30px;

			img {
				height: 27vw;
				width: 100%;
				object-fit: cover;
			}

			.zone_texte {
				position: absolute;
				left: 0;
				bottom: 0;
				background-color: $color_white;
				padding-top: 10px;
				padding-right: 30px;
				margin-left: 15px;

				* {
					margin: 0;
				}

				.surtitre {
					text-transform: uppercase;
					font-size: 0.9rem;
					line-height: 1;
				}

				.categorie {
					font-size: 1.2rem;
					font-weight: 800;
					color: $color_orange;
				}

				.titre {
					font-size: 1.75rem;
					font-weight: 800;
					color: $color_black;
					line-height: 1;
				}
			}

			.btn {
				position: absolute;
				right: 50px;
				bottom: 0;
			}

		}
		
	}

	section#vous_aimerez_aussi {
		margin-top: 50px;

		#liste_realisations {
			margin-top: 30px;
		}
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#entete_page {
			margin-bottom: 100px !important;

			#titre_page {
				min-height: 400px;
				margin-bottom: 50px;

				.bg_image {
					width: 100%;
					bottom: 40px;
				}
			}

			.separateur { display: none; }

			.titre {
				margin-bottom: 40px;
			}

			.documentation {
				display: block;

				* {
					display: block;
					margin: 0;
				}

				a {
					margin: auto;
				}

				.picto_pdf {
					margin: 20px auto;
					width: 20%;
				}
			}
			
		}

		#liste_realisations {
			img {
				height: 60vw !important;
			}

			.categorie {
				font-size: 1.5rem;
			}

			.titre {
				font-size: 1.3rem;
			}
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

	#liste_temoignages {
		.temoignage_item  {
			margin-bottom: 30px;

			.legende_titre {
				font-size: 1.2rem;
				font-weight: 700;
				margin: 0;
			}
	
			.citation {
				font-size: 1.4rem;
				line-height: 1;
				margin-bottom: 8px;
			}
	
			.legende_soustitre {
				font-weight: 700;
			}
	
			.texte {
				line-height: 1.2;
			}

			img {
				@extend %imgcover;
			}
		}
	}

}

.realisations.single, .poteaux-poutres.single, .ossature-bois.single, .parent_9.single {
	#titre_page {
		text-align: right;
	}

	#single_realisation {
		margin: 40px 0;
		
		.titre_chapo {
			margin-bottom: 24px;

			.titre {
				font-size: 1.6rem;
				font-weight: 700;
				margin-bottom: 15px;
				line-height: 1;
			}
			.chapo {
				font-size: 1.4rem;
				font-weight: 500;
				line-height: 1;
			}
		}

		.entete {
			position: relative;

			.photos_btn_container {
				position: absolute;
				top: -20px;
				left: 50%;
				transform: translate(-50%, -100%);
				text-align: center;

				.photos_btn {
					display: block;
				}

				svg {
					display: inline-block;
					margin-top: 10px;
					width: 40px;
				}

				@include media-breakpoint-down($responsive_breakpoint) {
					display: none;
				}
			}

			.texte {
				//transform: translateY(-40px);
				
				p {
					margin-bottom: 12px !important;
				}
				p:last-child {
					margin-bottom: 0 !important;
				}

				@include media-breakpoint-down($responsive_breakpoint) {
					margin-bottom: 30px;
				}
			}

			.spacer {
				.trait {
					height: 100%;
					width: 1px;
					margin: auto;
					//background-color: $color_black;
				}
			}
			
			.image {
				position: relative;

				img {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;

					@include media-breakpoint-down($responsive_breakpoint) {
						position: relative;
					}
				}
			}
		}

		

		.fleche {
			margin: 30px 0;
			text-align: center;
			font-size: 2rem;
			color: $color_black;

			svg {
				width: 40px;
			}
		}

		.zone_images {
			img {
				width: 100% !important;
				object-fit: cover;
			}

			.fullwidth_container {
				margin: 20px 0;

				img { max-height: 600px; }
			}

			.demi_container {
				display: flex;
				margin: 20px 0;

				img {
					max-height: 30vw;
					//max-width: calc(50% - 10px);
				}

				.spacer {
					margin: 0 10px;
				}
			}

			.ligne {
				display: block;
				height: 1px;
				width: 75%;
				margin: auto;
				background-color: rgba($color_black, 0.5);
			}
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {


		#single_realisation { margin-bottom: 0 !important; }
		
	}


}


.rea_collectives {
	.logo_const_collectives {
		position: absolute;
		top: 0;
		right: 0;
		height: 100px;
		width: 100px;
	}

	h1 {
		padding-right: 100px;
	}

	#texte_droite {
		text-align: right;

		p:nth-child(2) {
			margin-bottom: 15px;
		}
	}

	.tel {
		font-size: 1.6rem;
		font-weight: 900;
		color: $color_black;
		text-decoration: none;
		letter-spacing: 0.05rem;
	}
}