
.developpement-durable {
    h2 {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0;
    }

    .phrase_entetes  {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;

        #breadcrumb {
            color: $color_white;
        }
    }

    section {
        margin-bottom: 130px;

        @include media-breakpoint-down($responsive_breakpoint) {
            margin-bottom: 60px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    section#entete {
        .overlay {
            position: absolute;
            z-index: 10;
            bottom: 120px;
            left: 60px;
            max-width: 500px;
            color: $color_white;

            @include media-breakpoint-down($responsive_breakpoint) {
                bottom: 40px;
                left: 30px;
                right: 30px;
            }

            .grostitre {
                color: $color_white;
                margin-bottom: 30px;

                strong {
                    font-weight: 700;
                    color: $color_vert;
                }
            }

            .texte {
                font-size: 1.3rem;
                line-height: 1.2;
            }
        }

        img {
            min-height: 500px;
            object-fit: cover;
        }

        .overlay_bas {
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
            right: 0;
            height: 85%;
            background: linear-gradient(transparent, rgba($color_black, 0.6));

            @include media-breakpoint-down($responsive_breakpoint) {
                height: 100%;
            }
        }
    }

    section#section1 {
        img {
            margin-bottom: 20px;
        }
    }

    section#section2 {
        .col_gauche {
            margin-bottom: 30px;
        }
    }

    .module_nous_contacter {
        .col_gauche{
            background-color: rgba($color_vert, 0.2);
        }

        h2 {
            font-size: 2.5rem;
            margin-bottom: 16px;
        }
    }
}