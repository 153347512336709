
#modal_event {
	height: auto;

	.modal-dialog {
		height: auto !important;
	}

	.img_verticale { display: none }
	.img_horizontale { display: block }

	img {
		width: 100%;
		height: auto;
	}

	a {
		position: absolute;
		bottom: -20px;
		left: 50%;
		transform: translate(-50%, 100%);
	}
}

#modal_video {
	.modal-dialog{
		width: 80vw !important;
		height: 45vw !important;

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	.close {
		right: -10px;
		transform: translateX(100%);
		color: white;
	}
}

@include media-breakpoint-down(xs) {
#modal_event {
	.img_verticale { display: block }
	.img_horizontale { display: none }
}
}

.accueil, .id_1 {

	.navbar_container {
		.navbar {
			position: absolute;
			top: 0;
			background-color: rgba($color_black, 0.4);
			transition: 0.5s;
		}
		.navbar:hover {
			background-color: rgba($color_white, 1);

			.nav-link {
				color: $color_black;
			}
		}
	}

	/* .site_content {
		margin-bottom: 200px;
	} */

	#section1 {
		color: $color_white;

		.txt_container.desk { display: block; }
		.txt_container.resp { display: none; }

		@include media-breakpoint-down(md) {
			.txt_container.desk { display: none; }
			.txt_container.resp { display: block; }
		}

		#carousel_accueil {

			@include media-breakpoint-down(md) {
				height: calc(120vw);
				max-height: 80vh;
			}

			.overlay_bas {
				position: absolute;
				z-index: 1;
				left: 0;
				bottom: 0;
				right: 0;
				height: 75%;
				background: linear-gradient(transparent, rgba($color_black, 0.5));

				@include media-breakpoint-down($responsive_breakpoint) {
					background: linear-gradient(rgba($color_black, 0.5), transparent);
					top: 0;
					height: 100%;
				}

				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			.overlay_points {
			    position: absolute;
			    z-index: 1;
			    height: 100%;
			    width: 100%;
			    background-image: url(../images/logos_pictos/overlay_video.png);
			    background-repeat: repeat;

			    display: none;
			}

			.carousel-control {
				z-index: 10;
			}

			.content_over_carousel {
				z-index: 10;
				top: 150px;
				right: 10vw;
				left: 0;
				bottom: 0;

				h2 {
					color: $color_orange;
					margin: 0;
					margin-left: 20px;
					text-align: left;
				}

				/*> .filet_orange {
					margin-left: auto;
					margin-right: 50px;
				}*/

				img {
					display: block;
					margin-left: auto;
					width: 15vw;
					max-width: 100px;
					margin-top: calc(70vh - 150px);
					transform: translateY(-100%);
				}

				#lien_film {
					display: block;
					position: absolute;
					right: 0;
					bottom: 100px;
					background-color: rgba($color_orange, 0.8);
					color: #fff;
					text-align: center;
					display: block;
					width: 130px;
					height: 130px;
					line-height: 130px;
					border-radius: 50%;
					text-decoration: none;
					font-size: 1.75rem;
					transition: 0.25s;

					&:after {
						display: block;
						content: "";
						position: absolute;
						top: calc(50% + 30px);
						left: 50%;
						transform: translateX(-50%) rotate(-45deg);
						background: url(../images/logos_pictos/fleche-blanche-droite.svg) center center no-repeat;
						background-size: contain;
						width: 39px;
						height: 24px;
						transition: 0.25s;
					}

					&:hover {
						background-color: rgba($color_orange, 1);

						// &:after {
						// 	transform: translateY(-50%);
						// 	left: 75%;
						// 	top: 50%;
						// 	width: 30px;
						// }
					}
				}
			}

			.txt_container {
				position: absolute;
				bottom: 5vw;
				left: 8vw;
				max-width: 550px;

				@include media-breakpoint-down($responsive_breakpoint) {
					top: 80px;
					bottom: initial;
				}
			}
		}

		.txt_container {

			&.resp {
				color: $color_black;
				padding: 20px;
				margin-bottom: 30px;
			}

			.surtitre {
				margin: 0;
				font-weight: 700;
				color: $color_orange;
			}

			.titre {
				margin-top: 10px;
				font-size: 2rem;
				font-weight: 700;

				strong {
					color: $color_orange;
					font-weight: 700;
				}
				
			}

			.texte {
				font-size: 1.3rem;
				line-height: 1.3;
			}

			a {
				padding: 0.2rem 1.5rem;
				font-size: 0.9rem;
			}
		}
	}


	#section2 {
		z-index: 10;
		transform: translateY(-30px);
		
		.image {
			position: relative;
			display: block;

			img {
				width: 100%;
				height: 30vw;
				object-fit: cover;
			}

			.overlay {
				position: absolute;
				left: 0;
				bottom: 0;
				background-color: $color_white;
				//padding-top: 10px;
				padding-right: 20%;
			}
		}

		.surtitre {
			font-size: 1rem;
			font-weight: 700;
			margin: 0;
		}

		.titre {
			margin: 0;
			font-size: calc(1.1rem + 1vw);
			line-height: calc(1.1rem + 1vw);
			font-weight: 800;
			color: $color_orange;

			p { margin: 0; }
		}

		.soustitre {
			font-weight: 700;
			margin-top: 14px;
		}
	}


	#section3 {

		img {
			width: 100%;
		}
	}


	#section4 {
		
		img {
			width: 80%;
			margin-left: 10%;
		}
	}


	#section_terrains {
		//display: none;
		margin-bottom: 70px;

		.bg_grey {
			background-color: $color_grisclair;
		}

		.gauche {
			padding: 30px;

			.buttons_container {
				margin-top: 30px;
				text-align: right;
			}
		}

		.droite {
			padding-right: 0;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		
	}


	#section_obligations {
		margin-bottom: 50px;

		img {
			width: 100%;
			max-height: 500px;
			object-fit: cover;
		}
	}


	#section5 {
		margin-top: 30px;
		background-color: $color_vert;
		padding-top: 40px;

		.entete {
			display: flex;
			justify-content: space-between;
			margin-bottom: 40px;

			@include media-breakpoint-down($responsive_breakpoint) {
				flex-direction: column;
				align-items: center;
			}
		}

		.grostitre {
			color: $color_white;
			margin: 0;

			@include media-breakpoint-down($responsive_breakpoint) {
				margin-bottom: 20px;
			}
		}
		
		.content_actus {
			position: relative;
			padding-bottom: 40px;

			.white_filler {
				position: absolute;
				z-index: 1;
				top: 0;
				width: 100%;
				height: 60px;
				background-color: $color_white;
			}
		}

		.content_actus > .btn {
			display: block;
			width: 300px;
			margin: auto;
		}
	}


	#section6 {
		margin-bottom: 80px;
	}


	#section7 {
		color: $color_black;
		margin-top: 80px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.symbole_citation {
			position: absolute;
			top: 0;
			width: 100%;
			transform: translateY(-40px);
			text-align: center;
			line-height: 4rem;
			font-size: 4rem;
			height: 30px;
		}

		.citation {
			text-align: center;
			line-height: calc(1.2rem + 1vw);
			font-size: calc(1rem + 1vw);
			margin-bottom: 4vw;
		}

		.legende_titre {
			margin: 0;
			font-size: 1.3rem;
			font-weight: 700;
		}

		.legende_soustitre {
			margin: 0;
			line-height: 0.8rem;
		}

		.btn {
			transform: translateY(-40px);
		} 
	}


	#section_pourquoi {
		h3 {
			font-size: 1rem;
			margin-bottom: 0;
		}
	}


	@include media-breakpoint-down($responsive_breakpoint) {

		.navbar_container {
			.navbar {
				.nav-link {
					color: $color_white !important;
				}
			}
			.navbar:hover {
				.nav-link {
					color: $color_white !important;
				}
			}
		}

		#section1 {
			.content_over_carousel {
				top: 10px !important;

				.font_signpainter {
					text-align: left !important;
					font-size: 1.3rem;
					margin-left: 90px !important;
				}

				/*.font_signpainter, > .filet_orange {
					transform: translateY(60px);
				}*/

				.txt_container {
					bottom: auto;
					top: auto;
					bottom: 17% !important;
					/*transform: translateY(-50%);*/

					p {
						line-height: 1.2;
					}

					> p, > a {
						display: none;
					}

					h3 {
						font-size: 1.4rem;
					}

					.titre {
						font-size: 1.45rem !important;
						p { line-height: 1.2; }
					}
				}

				img {
					transform: translate(20px, 20px) !important;
				}

				> .filet_orange {
					margin-left: 90px !important;
				}
			}

			.carousel-indicators {
				margin: 0;
				justify-content: left;
				margin-left: 20px;
			}
		}

		#section2 {
			.image img {
				height: 60vw;
			}
		}

		#section5 {
			#carousel_news img {
				height: 50vw;
			}

			.content_actus {
				padding-top: 40px;

				@include media-breakpoint-down($responsive_breakpoint) {
					padding-top: 0;
				}

				.news_item {
					margin-bottom: 30px;
				}
			}

			.white_filler {
				display: none;
			}
		}

		#section7 {
			img {
				margin-bottom: 40px;
			}

			.symbole_citation {
				transform: translateY(-30px);
			}

			.citation {
				br { display: none; }
			}

			.btn {
				display: block;
				float: none !important;
				width: 100%;
				transform: none;
				margin: 20px 0;
			}

		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


