
// -----------------------------------
// ||           Carousels           ||
// -----------------------------------


// -------------- Options --------------
$carousel_id: 'carousel_accueil';
$carousel_height: calc(70vh + 10vw);
$carousel_width: 100%;
$carousel_control_width: 5%;
$carousel_control_size: 4rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 10px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		//height: $carousel_height !important;
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; text-decoration: none; }
	.carousel-control-next { font-size: $carousel_control_size; text-decoration: none; }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		margin-left: 60px;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}



// -------------- Options --------------
$carousel_id: 'carousel_news';
$carousel_height: auto;
$carousel_width: 100%;
$carousel_control_width: 4%;
$carousel_control_size: 3rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 30px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			/* img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			} */
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; text-decoration: none; }
	.carousel-control-next { font-size: $carousel_control_size; text-decoration: none; }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}


// -------------- Options --------------
$carousel_id: 'carousel_realisations';
$carousel_height: auto;
$carousel_width: 100%;
$carousel_control_width: 4%;
$carousel_control_size: 3rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 30px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			/* img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			} */
		}
	}
	.carousel-control {
		//width: $carousel_control_width;
		width: auto;
		opacity: 1;

		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}

		.rond {
			height: 42px;
			width: 42px;
			border-radius: 50%;
			background-color: $color_orange;
		}

		svg {
			@extend %abcenter_xy;
			height: 18px;
			width: 30px;
		}
	}
	.carousel-control-prev { 
		font-size: $carousel_control_size; 
		text-decoration: none; 
		left: 5vw;
		transform: translateX(-50%);
	}
	.carousel-control-next { 
		font-size: $carousel_control_size; 
		text-decoration: none; 
		right: 5vw;
		transform: translateX(50%);
	}
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}




// -------------- Options --------------
$carousel_id: 'carousel_single_news';
$carousel_height: 300px;
$carousel_width: 100%;
$carousel_control_width: 10%;
$carousel_control_size: 3rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 10px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 1000px;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		text-decoration: none;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; }
	.carousel-control-next { font-size: $carousel_control_size; }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}


// -------------- Options --------------
$carousel_id: 'carousel_modal';
$carousel_height: 100%;
$carousel_width: 100%;
$carousel_control_width: 10%;
$carousel_control_size: 3rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 20px;
$carousel_indicators_height: 12px;
$carousel_indicators_width: 12px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0);
$carousel_indicators_border: 1px solid $color_white;
$carousel_indicators_borderradius: 1000px;
$carousel_indicators_active_color: rgba($color_white, 0);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 6px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.5s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		text-decoration: none;
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev { font-size: $carousel_control_size; }
	.carousel-control-next { font-size: $carousel_control_size; }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}