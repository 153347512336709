.modal_galerie_news {
    //position: fixed;
    //height: 100%;
    display: none !important;
}
.modal_galerie_news.show {
    //position: fixed;
    //height: 100%;
    display: block !important;
}

.modal {
    padding-right: 0px !important;
}

.modal-dialog.centered {
	margin: 0 !important;
	@extend %absolutecenter_xy;
	transform: translate(-50%, -50%) !important;
}

// MODALE MINIMALISTE, BORDERLESS ETC
.modal.bare {
    

    .modal-dialog {
    	
    	.modal-content {
    		border-radius: 0 !important;
    		border: none;

    		button.close {
    			cursor: pointer;
    			position: absolute;
    			z-index: 10;
    			top: 10px;
    			right: 10px;
    			font-size: 3rem;
    			line-height: 0.5em;
    			color: $color_black;
    		}

    		.modal-body {
    			padding: 0;
    		}
    	}
    }
}


// MODALE AVEC CAROUSEL DEDANS
.carousel_modal {

	.modal-dialog {
		width: 80vw !important;
		height: 80vh !important;
		max-width: none;

		.modal-content {
			height: 100%;
		}
	}

	.carousel {
        position: relative;
		height: 80vh !important;
	}

    img {
        height: 80vh;
        width: 100%;
        object-fit: cover;
    }

}

.modal_video  {

	.modal-dialog {
		margin: 0;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) !important;
		width: 80vw !important;
		height: 80vw * 9 / 16 !important;

		iframe {
			width: 80vw !important;
			height: 80vw * 9 / 16 !important;
		}
	}
}

