
.construire-en-bois, .id_7, .id_8d, .monitoring-solaredge, .rendement-panneaux-solaires {
	color: $color_black;

	.site_content {
	    max-width: 1500px;
	    margin-left: auto;
	    margin-right: auto;
	}

	#contenu_page {
		margin-top: 30px;
		margin-bottom: 40px;
	}

	.txt_centered { 
		.titre, .texte {
			text-align: center;
		}

		img {
			margin-top: 20px;
		}
	}

	section {

		h3 {
			font-size: 2.6rem;
			font-weight: 600;
			margin-bottom: 20px;
		}

		img {
			width: 100%;
		}

		.gauche { padding-right: 15px; }
		.droite { padding-left: 15px; }
	}

	.filet {
		width: 100%;
		height: 1px;
		background-color: $color_black;
		margin: 50px 0;
	}

	#module_construire {
		margin-top: 120px;
		margin-bottom: 50px;
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		section {
			br { display: none; }

			.gauche { padding-right: 0; }
			.droite { padding-left: 0; }

			.titre {
				font-size: 2rem;
			}
		}

		#section_4, #section_6 {
			.droite .titre {
				margin-top: 20px;
			}
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}

.monitoring-solaredge {

}

#section_monitoring {
	margin: 0;
	
	@include media-breakpoint-down($responsive_breakpoint) { 
		margin: 0;

		.gauche, .droite {
			padding: 0;
		}
	}

	.bloc_infos {
		border: 1px solid darken($color_vert, 10%);
		border-radius: 10px;
		margin-bottom: 30px;
		overflow: hidden;

		.titre_bloc {
			padding: 10px;
			background-color: darken($color_vert, 10%);
			color: $color_white;
			margin: 0;

			.last_update {
				font-size: 1rem;
				font-style: italic;
			}

			input, select {
				font-size: 1.2rem;
			}

			button, a {
				background-color: $color_orange;
				border: none;
				margin-left: 5px;
				padding: 0.2rem 0.75rem;
				text-decoration: none;
			}

			.carre {
				display: inline-block;
				height: 1rem;
				width: 1rem;
				margin: 0 3px;
				border: 1px solid $color_white;
			}
			.carre.orange { background-color: $color_orange; }
			.carre.taupe { background-color: $color_taupe; }
		}

		.contenu {	

			.attente {
				padding: 30px;
				text-align: center;
				margin: 0;
			}

			.infobloc {
				display: flex;
				align-items: center;
				padding: 20px;
				border-bottom: 1px solid darken($color_vert, 10%);
				
				.icon_container {
					margin-right: 10px;
					width: 50px;
					flex-shrink: 0;
					text-align: center;
					
					i {
						color: $color_vert;
						font-size: 2.4rem;
					}
				}

				p {
					margin: 0;
				}

				.label {
					font-size: 1.1rem;
					line-height: 1;
					margin-bottom: 2px;
				}

				.value {
					font-size: 1.4rem;
					font-weight: 700;
					line-height: 1;
					color: $color_orange;
				}
			}

			.infobloc:last-child {
				border-bottom: none;
			}
		}

		.contenu.horiz {
			display: flex;

			.bloc_horiz {
				flex: 1;
				text-align: center;
				border-right: 1px solid darken($color_vert, 10%);
				padding: 10px 0;
			}
			.bloc_horiz:last-child {
				border-right: none;
			}

			.label {
				font-size: 1rem;
				line-height: 1;
				margin-bottom: 6px;
			}

			.value {
				font-size: 1.4rem;
				font-weight: 700;
				line-height: 1;
				color: $color_orange;
				margin: 0;
			}

			.bloc_horiz.instant {
				background-color: $color_orange;

				* {
					color: $color_white;
				}
			}
		}
	}

	.graphique_container {
		position: relative;
		padding-left: 55px;
		//padding-right: 20px;
		padding-top: 10px;
		padding-bottom: 40px;

		.grille.small {
			height: 200px;
			margin-right: 20px;
			margin-left: -10px;
			transform: translateY(8px);

			.label_w {
				top: -8px;
			}
		}
		.grille {
			position: relative;
			display: flex;
			align-items: flex-end;
			height: 400px;

			.label_w {
				position: absolute;
				top: 0px;
				left: -10px;
				font-weight: 700;
				transform: translateX(-100%)
			}

			.label_date {
				position: absolute;
				bottom: -5px;
				left: 50%;
				font-weight: 700;
				transform: translate(-50%, 100%)
			}

			.trait_h {
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: grey;

				label {
					position: absolute;
					left: -10px;
					transform: translate(-100%, -50%);
				}
			}
			.trait_h._0 { bottom: 0; height: 2px; }
			.trait_h._1 { bottom: 10%; }
			.trait_h._2 { bottom: 20%; }
			.trait_h._3 { bottom: 30%; }
			.trait_h._4 { bottom: 40%; }
			.trait_h._5 { bottom: 50%; }
			.trait_h._6 { bottom: 60%; }
			.trait_h._7 { bottom: 70%; }
			.trait_h._8 { bottom: 80%; }
			.trait_h._9 { bottom: 90%; }
			.trait_h._10 { bottom: 100%; }

			.graph_bar_container {
				display: flex;
				align-items: flex-end;
				position: relative;
				flex: 1;
				padding: 0 4px;
				height: 100%;

				.date {
					position: absolute;
					z-index: 15;
					bottom: -2px;
					left: 50%;
					transform: translate(-50%, 100%);
				}
			}
			.graph_bar_container.smallpad {
				padding: 0 1px;
			}

			.graph_bar, .graph_bar.current { background-color: rgba($color_orange, 0.92); }
			.graph_bar:hover, .graph_bar.current:hover { background-color: lighten($color_orange, 15%); }
			.graph_bar.last { background-color: rgba($color_taupe, 0.92); }
			.graph_bar.last:hover { background-color: lighten($color_taupe, 15%); }
			.graph_bar {
				position: relative;
				flex: 1;
				min-width: 1px;

				.label {
					pointer-events: none;
					position: absolute;
					z-index: 10;
					top: -5px;
					left: 50%;
					transform: translate(-50%, -100%);
					background-color: lightgrey;
					padding: 2px 10px;
					border-radius: 1000px;
					white-space: nowrap;
					opacity: 0;
					transition: 0.3s;
				}

				.date {
					pointer-events: none;
					position: absolute;
					z-index: 15;
					bottom: -5px;
					left: 50%;
					transform: translate(-50%, 100%);
					opacity: 0;
					transition: 0.3s;
					padding: 2px 10px;
					background-color: $color_white;
				}
			}
			.graph_bar:hover {
				.label, .date {
					opacity: 1;
				}
			}

			.graph_bar.top_only {
				background-color: transparent;
				border-top: 4px solid $color_orange;
			}
		}
	}
}