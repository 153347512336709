
.club-des-ambassadeurs {

	#contenu_page {
		h2 {
			display: block;
			width: 100%;
			text-align: right;
		}

		.image {
			padding-right: 2vw;

			/*img {
				display: block;
				margin: auto;
				max-width: 170px;
			}*/
		}
	}
	
	#liste_news {
		margin-top: 50px;

		.titre_container {
			align-items: center;
			margin-bottom: 40px;
		}

		.titre_liste {
			white-space: nowrap;
			padding-right: 5vw;
			margin: 0;
			font-size: 1.5rem;
			font-weight: 900;
			color: $color_orange;
			text-transform: uppercase;
		}

		.filet {
			width: 100%;
			height: 1px;
			background-color: $color_black;
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {

		.soustitre_page {
			text-align: left !important;
		}

		#section {
			
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


