
// ----------------------------------
// ||            Global            ||
// ----------------------------------

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}
section a:hover {
	color: $links_color;
}

img {
	height: auto;
}
picture {
	width: 100%;
}

// textarea,
// input.text,
// input[type="text"],
// input[type="button"],
// input[type="submit"],
// div[type="submit"],
// *[type="submit"],
// button, 
// input {
// 	/*-webkit-appearance: none !important;
// 	-moz-appearance: none !important;
// 	appearance: none !important;*/
// }

.full_site {
	position: relative;
	min-height: 100vh;
}

.hidden {
	display: none;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

.grecaptcha-badge { display: none; }

img.hidden {
	display: none;
}

.bg_white {
	background-color: $color_white;
}


// ----------------- CSS SPECIFIQUE AU SITE -----------------

.site_content {
	position: relative;
	min-height: 45vh;
}

.padding_side {
	padding-left: $fullwidth_padding_sides;
	padding-right: $fullwidth_padding_sides;
}

.maxed_width {
	max-width: 1400px;
	margin-left: auto !important;
	margin-right: auto !important;
}

.col_separator {
	width: 34px;
	height: 34px;
}

.separateur_fullwidth {
	height: 1px;
	background-color: $color_black;
}

.filet_orange {
	width: 150px;
	margin-left: 50px;
	height: 1px;
	background-color: $color_orange;
}

.font_signpainter {
	font-family: house-script, sans-serif;
}

.capitalize {
	text-transform: capitalize;
}

.soustitre_page {
	font-size: 2rem;
	font-weight: 900;
}

p.error {
	color: red;
}

.btn {
	cursor: pointer;
}

.btn.type_1, .btn.type_2, .btn.type_3, .btn.type_4, .btn.type_5 {
	position: relative;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	border-radius: 1000px;
	padding: 0.4rem 3rem;
	font-weight: 600;

	&.arrow {
		padding: 0.4rem 5rem;
		&:after {
			display: block;
			content: "";
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			background: url(../images/logos_pictos/fleche-blanche-droite.svg) center center no-repeat;
			background-size: contain;
			width: 30px;
			height: 24px;
		}
	}
}

.btn.type_1 {
	color: $color_white;
	border: 1px solid $color_white;
}
.btn.type_1:hover {
	color: $color_white;
	background-color: rgba($color_white, 0.05);
}

.btn.type_2 {
	color: $color_black;
	border: 1px solid $color_black;
	box-shadow: 2px 2px 3px rgba($color_black, 0.3);
}
.btn.type_2:hover {
	color: $color_black;
	background-color: rgba($color_black, 0.05);
}

.btn.type_3 {
	color: $color_white;
	background-color: $color_orange;
}
.btn.type_3:hover {
	color: $color_white;
	//background-color: lighten($color_orange, 5%);
	background-color: $color_orangefonce;
}

.btn.type_4 {
	color: $color_white;
	background-color: $color_taupe;
	font-weight: 900;
	letter-spacing: 0.05rem;
}
.btn.type_4:hover {
	color: $color_white;
	background-color: lighten($color_taupe, 5%);
}

.btn.type_5 {
	color: $color_orange;
	background-color: $color_white;
	border: 1px solid $color_orange;
	font-weight: 900 !important;
	letter-spacing: 0.05rem;
}
.btn.type_5:hover {
	color: $color_orange;
	background-color: darken($color_white, 5%);
}


.grostitre {
	font-size: calc(1.5rem + 0.4vw);
	line-height: calc(1.5rem + 0.4vw);
	font-weight: 700;
	color: $color_black;
	margin-bottom: 1.5rem;
}

.titre_center {
	text-align: center;
	font-size: calc(1.4rem + 1vw);
	line-height: calc(1.4rem + 1vw);
	font-weight: 700;
	color: $color_black;
}

.phrase_entetes {
	color: $color_orange;
	padding-top: 20px;
	text-align: right;
	padding-bottom: 30px;

	.gauche {
		text-align: left;
	}

	.filet_orange {
		margin-left: 30px;
		//margin-right: 30px;
	}
}

#titre_page {

	h2, h3, .surtitre {
		margin: 0;
		font-size: 1.4rem;
		font-weight: 900;
		line-height: 1;
		color: $color_orange;
	}

	h1, .h1 {
		margin: 0;
		//font-size: 2.5rem;
		font-weight: 900;
	}
}

.no_parent #titre_page {
	h3 {
		display: none;
	}
}

#contenu_page h3, #entete_page h3 {
	margin-top: 16px;
	font-size: 1.1rem;
}

#breadcrumb {
	list-style: none;
	padding: 0;
	color: $color_black;

	li {
		display: inline-block;
		padding-right: 6px;
	}

	li:after {
		content: ' > ';
	}

	li:last-child:after {
		content: '';
	}

	a {
		color: $color_orange;
	}
}

#carousel_news {
	z-index: 10;

	.news_item {
		position: relative;
	} 

	.news_img_container {
		padding: 0 15px;
	}

	img {
		width: 100%;
		height: 16vw;
		object-fit: cover;
		object-position: center top;
	}

	.text_container {
		padding: 16px;
		color: $color_white;
		//background-color: $color_white;

		.date {
			margin: 0;
			font-size: 1.1rem;
			font-weight: 400;
			color: $color_white;
			text-transform: capitalize;

			span {
				text-decoration: underline;
			}
		}

		.titre {
			margin-bottom: 16px;
			font-size: 1.5rem;
			font-weight: 800;
			color: $color_white;
		}

		a {
			text-decoration: none;
		}
	}

	.trait_vertical {
		position: absolute;
		right: -7px;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1px;
		background-color: $color_white;
	}
}

.realisations_module_container {
	padding-top: 30px;
	padding-bottom: 30px;
	color: $color_white;
	background-color: $color_taupe;

	.titre_center {
		margin-bottom: 30px;
		color: $color_white;
	}

	.realisation_item {
		img {
			height: 20vw;
			width: 100%;
			object-fit: cover;
		}
	}

	.bas {
		margin-bottom: 20px;

		.texte {
			text-align: center;
			margin: 0;
			margin-top: 20px;
		}

		.btn {
			display: inline-block;
			//width: 300px;
			margin: auto;
		}
	}
}

#module_construire {

	h2 {
		text-align: center;
		font-weight: 700;
		margin: 20px 0;
	}

	.image {
		position: relative;
		display: block;

		img {
			width: 100%;
			height: 30vw;
			object-fit: cover;
		}

		.overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $color_white;
			padding-top: 10px;
			padding-right: 20%;
		}
	}

	.surtitre {
		font-weight: 700;
		margin: 0;
	}

	.titre {
		margin: 0;
		font-size: calc(1.1rem + 1vw);
		line-height: calc(1.1rem + 1vw);
		font-weight: 800;
		color: $color_orange;

		p { margin: 0; }
	}

	.soustitre {
		font-weight: 700;
		margin-top: 14px;
	}
}

#contact_form_footer_titre { transform: translateY(20px); }
#contact_form_footer { transform: translateY(100px); }

#contact_form_footer_titre {
	height: 0;
}

#contact_form_footer {
	display: block;
	margin: auto;
	max-width: 700px;
	background-color: $color_white;
	padding: 24px;
	border-radius: 10px;
	box-shadow: 3px 3px 6px rgba($color_black, 0.2);

	.grostitre {
		position: absolute;
		top: -20px;
		left: 0;
		transform: translateY(-100%);
	}

	.open_close_button {
		position: absolute;
		right: 10px;
		top: 0;
		transform: translateY(calc(-100% + 10px));
		padding: 4px 8px;
		background-color: $color_orange;
		border-radius: 6px 6px 6px 0;
		transition: 0.6s;

		.icon {
			color: $color_white;
			opacity: 1;
			font-size: 1.3rem;
		}

		.bottom_arrow {
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translateY(100%);
			width: 16px;
			height: 8px;
			border-top: 4px solid $color_orange;
			border-left: 8px solid $color_orange;
			border-bottom: 4px solid transparent;
			border-right: 8px solid transparent;
		}
	}

	.input_container {
		.txtinput {
			width: 100%;
			border: none;
			border-radius: 4px;
			margin-bottom: 6px;
			padding: 2px 10px;
			color: $color_black;
			background-color: $color_grisclair;
		}

		.txtinput::placeholder {
			color: $color_grisfonce;
			opacity: 1;
			font-style: italic;
		}

		.btn {
			cursor: pointer;
			float: right;
		}

		.form-input {
			margin-bottom: 6px;
		}
		.form-input:nth-child(even) { padding-left: 6px; }
		.form-input:nth-child(odd) { padding-right: 6px; }
	}

	.col_image {
		img {
			width: 100%;
			max-width: 190px;
		}
	}

	// .col_bouton {
		
	// }

	@include media-breakpoint-down($responsive_breakpoint) {
		.text-right { 
			text-align: center !important; 
		
			.btn {
				transform: none;
				left: 0;
			}
		}
	}
}

.id_37 {
	#contenu_page {
		margin: 80px 0;

		// ul {

		// }
	}
}

.logo_resp { display: none;  }

.id_36, .id_200, .mentions-legales, .politique-de-confidentialité {
	#contenu_page {
		margin-top: 40px;
	}

	h3 {
		font-size: 1.6rem;
		font-weight: 700;
		margin-top: 30px;
	}
}


.erreur-404 {
	.site_content {
		text-align: center;

		h1 {
			font-size: 4rem;
		}

		p {
			font-size: 1.3rem;
		}
	}

	.liens_realisations_404 {
		z-index: 10;
		margin: 40px 0;
		
		.image {
			position: relative;
			display: block;

			img {
				width: 100%;
				height: 30vw;
				object-fit: cover;
			}

			.overlay {
				position: absolute;
				left: 0;
				bottom: 0;
				background-color: $color_white;
				padding-top: 10px;
				padding-right: 20%;
			}
		}

		.overlay {
			text-align: left;

			.surtitre {
				font-size: inherit;
				font-weight: 700;
				margin: 0;
			}
	
			.titre {
				display: block !important;
				margin: 0 !important;
				font-size: calc(1.1rem + 1vw) !important;
				line-height: calc(1.1rem + 1vw) !important;
				font-weight: 800 !important;
				color: $color_orange !important;
	
				p { 
					margin: 0; 
					font-size: inherit;
				}
			}
		}

		.soustitre {
			font-weight: 700;
			margin-top: 14px;
		}
		
		.btn {
			margin-top: 10px;
		}
	}
}

.module_nous_contacter {
	margin-top: 150px;

	.titre {
		font-size: 2.5rem;
		margin-bottom: 16px;

		strong {
			font-weight: 900;
		}
	}

	.col_gauche {
		padding: 20px 30px;
		padding-left: $fullwidth_padding_sides;
	}

	.col_droite {
		padding: 0;
	}

	img {
		@extend %imgcover;
	}
}

#entete_page {
	background-color: rgba($color_vert, 0.2);

	.col_gauche {
		padding-left: $fullwidth_padding_sides !important;
		padding-right: 35px !important;

		@include media-breakpoint-down($responsive_breakpoint) {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		.grostitre {
			margin-bottom: 25px;
		}

		.texte {
			h2, h3, h4 {
				font-size: 1.5rem;
				margin-bottom: 25px;
			}

			a {
				color: $color_black;
				text-decoration: underline;
			}
		}
	}

	.col_droite {
		padding-left: 15px;

		img {
			@extend %imgcover;
		}
	}
}


.more_container {
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 30px;

	&:before, &:after {
		content: " ";
		flex: 1;
		width: 100%;
		height: 1px;
		background-color: $color_black;
	}
	&:before {
		margin-right: 20px;
	}
	&:after {
		margin-left: 20px;
	}

	.counter {
		position: absolute;
		top: -20px;
		//left: calc(50% + 30px);
		right: 0;
		font-size: 1.8rem;
		font-weight: 700;
		line-height: 1;

		.total {
			color: $color_orange;
		}
	}

	.plus_container {
		cursor: pointer;
		height: 50px;
		width: 50px;
		border-radius: 1000px;
		background-color: $color_orange;

		.plus {
			@extend %abcenter_xy;
			font-size: 2.4rem;
			font-weight: 700;
			color: $color_white;
		}
	}

	.plus_container_txt {
		cursor: pointer;
		width: auto;
		border-radius: 1000px;
		color: $color_white;
		background-color: $color_orange;
		font-size: 1.3rem;
		font-weight: 700;
		line-height: 1;
		padding: 8px 16px;

		svg {
			position: absolute;
			left: 50%;
			bottom: -20px;
			transform: translateX(-50%) translateY(100%);
			width: 34px;

			* {
				stroke: $color_orange;
			}
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.1rem;
		}
	}
}

// ----------------------------------------------------------



@include media-breakpoint-down($responsive_breakpoint) {

	.logo_resp {
		display: block;
		position: absolute;
		z-index: 10;
		top: 5px;
		left: 5px;

		img {
			height: 60px;
			width: 60px;
		}
	}

	.phrase_entetes {
		display: block;
		padding-top: 80px;

		.col {
			display: block;
			height: 100%;
			width: 100%;
		}

		#breadcrumb {
			margin-bottom: 10px;
		}

		.font_signpainter {
			margin: 0;
		}
	}

	#entete_page {
		flex-direction: column;
	}

	#titre_page {
		width: 100%;

		h3, .surtitre {
			font-size: 1.2rem;
		}

		.h1, h1 {
			font-size: 2rem;
		}
	}

	.soustitre_page {
		font-size: 1.7rem;
		font-weight: 900;
	}

	.btn.type_1, .btn.type_2, .btn.type_3, .btn.type_4, .btn.type_5 {
		font-size: 0.9rem;
		padding: 0.3rem 2rem;
	}

	.carousel-control {
		font-size: 2rem !important;
	}

	.realisations_module_container {
		.realisation_item img {
			height: 45vw;
			margin-bottom: 20px;
		}
	}

	#contact_form_footer_titre {
		margin-top: 80px;
		height: auto;
		transform: none;
	}

	#contact_form_footer {
		transform: none;
		margin-top: 50px;
		margin-bottom: 50px;
		width: 90%;
		margin-left: 5%;
		max-width: 10000px !important;
		text-align: center;

		.form-input {
			padding: 0 !important;
		}

		.col_bouton {
			text-align: center;
			a {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	#module_construire {
		.image img {
			height: 45vw;
		}
	}

}