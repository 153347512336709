
.resultats-recherche {

	#titre_page h3 {
		display: block;
	}

	#resultats {
		margin: 40px 0;

		.sisea-results {
			font-weight: 700;
		}

		.sisea-paging {
			display: none;
		}

		.result_item {
			margin-bottom: 50px;

			.gauche {
				padding-right: 20px;
			}

			.sisea-highlight {
				font-weight: 700;
			}

			img {
				width: 100%;
				max-height: 250px;
				object-fit: cover;
			}

			a {
				font-weight: 700;
				text-decoration: none;
			}

		}		
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#section {
			
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


