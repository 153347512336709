
// ----------------------------------
// ||            Footer            ||
// ----------------------------------


// ----------------- CSS SPECIFIQUE AU SITE -----------------

footer {

	.logo_footer {
		width: 100px;
		height: 100px;
	}

	.liens {
		p {
			margin: 0;
		}
	}

	.colonne.centre {
		align-items: flex-end;
		padding-bottom: 20px;
		border-bottom: 1px solid $color_white;
	}

	.coordonnees {
		max-width: 280px;

		p {
			margin: 0;
			font-size: 1.2rem;
			line-height: 1.4rem;
		}

		.telephone {
			margin-top: 6px;
		}

		.telephone, .fax {
			font-size: 1.6rem;
			line-height: 1.7rem;
			font-weight: 700;
		}
	}

	.colonne.bas {
		align-items: center;
		margin-top: 20px;
	}

	.bas {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include media-breakpoint-down($responsive_breakpoint) {
			flex-direction: column;
			align-items: flex-end;
		}
	}

	.baseline {
		margin: 0;
		margin-right: 30px;
		line-height: 1.2;

		@include media-breakpoint-down($responsive_breakpoint) {
			margin-bottom: 20px;
		}
	}

	.socials {
		margin: 0;
		display: flex;

		* {
			margin: 0;
			padding: 0;
		}

		.houzz, .facebook {
			display: block;
			//float: left;
			line-height: 2rem;
		}

		img {
			width: 110px;
			margin-right: 10px;
		}

		i {
			font-size: 2rem;
		}
	}

	.google_reviews_container {
		//float: left;
		margin-right: 20px;

		.google_reviews {
			display: flex;
			align-items: center;
			text-decoration: none;

			.google_picto {
				width: 26px;
				height: 26px;
				margin-right: 5px;
			}

			.stars_container {
				position: relative;
				font-size: 1.5rem;

				.stars_rating {
					position: absolute;
					top: 0;
					left: 0;
					overflow: hidden;
					white-space: nowrap;
					color: white;
				}

				.stars_background {
					color: grey;
				}
			}
		}
	}

}

#contact_form_footer {
	
}





// ----------------------------------------------------------


// FOOTER - GÉNÉRAL
footer {
	z-index: -1;
	background-color: $footer_bgcolor;
	padding: $footer_padding;
	.separateur_footer { width: $footer_separateur_horizontal; }
	color: $footer_txtcolor;
	a {
		color: $footer_link_color;
		@if $footer_link_underline == 1 { text-decoration: underline; }
	}
	a:hover {
		color: $footer_link_hover_color;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {

	footer {
		padding: 8vw 5vw;
		
		.separateur_footer { width: 100%; height: $footer_separateur_vertical; }

		img {
			margin: 0 auto;
		}

		.colonne.centre {
			flex-direction: column;

			.liens {
				width: 100%;
				text-align: center;
				margin-bottom: 40px;
				font-size: 1.2rem;
			}

			.coordonnees {
				width: 100%;
				max-width: 100%;
			}
		}

		.socials {
			margin-top: 20px;
		}
	}

}