
.carte-realisations, .id_25 {

	#entete_page {
		text-align: center;

		p {
			text-align: left;
		}

		.btn {
			margin-bottom: 30px;
		}
	}

	#carte_rea {
		width: 100%;
		height: 50vw;
		margin: 50px 0;

		iframe {
			border: none;
			width: 100%;
			height: 100%;
		}
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#entete_page {
			margin-bottom: 0px !important;
		}

		#titre_page {
			margin-bottom: 30px;
		}

		#carte_rea {
			height: 80vh;
			margin-top: 20px;
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


