
.temoignages, .id_52 {

	#contenu_page {
		h2 {
			display: block;
			width: 100%;
			text-align: right;
		}
	}
	
	#liste_temoignages {
		color: $color_black;
		margin-top: 60px;

		.temoignage_item {

			img {
				width: 100%;
			}

			.citation {
				text-align: center;
				line-height: calc(1.2rem + 1vw);
				font-size: calc(1rem + 1vw);
				margin-bottom: 50px;
			}

			@include media-breakpoint-up(xl) {
				.citation {
					line-height: 1.8rem;
					font-size: 1.8rem;
				}
			}

			.legende_titre {
				margin: 0;
				font-size: 1.3rem;
				font-weight: 700;
			}

			.legende_soustitre {
				margin: 0;
				line-height: 1.1rem;
			}

			.btn {
				transform: translateY(-40px);
			}
		}

		.filet {
			display: block;
			margin: 40px auto;
			width: 75%;
			height: 1px;
			background-color: rgba($color_black, 0.4);
		}
	}

	#single_temoignage {
		margin: 30px 0;

		.contenu {
			padding: 0 20px;
		}

		.citation {
			text-align: center;
			line-height: calc(1.2rem + 1vw);
			font-size: calc(1rem + 1vw);
			margin-bottom: 30px;
		}

		@include media-breakpoint-up(xl) {
			.citation {
				line-height: 1.8rem;
				font-size: 1.8rem;
			}
		}

		.legende_titre {
			margin: 0;
			font-size: 1.3rem;
			font-weight: 700;
		}

		.legende_soustitre {
			margin: 0;
			line-height: 0.8rem;
		}

		.texte_full {
			margin-top: 60px;
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {

		.soustitre_page {
			text-align: left !important;
		}

		.temoignage_item {
			/** { display: block; }*/

			img {
				height: 60vw;
				margin-bottom: 20px;
			}

			.citation {
				margin-bottom: 30px !important;
			}

			.btn {
				/*float: none !important;*/
				transform: none !important;
				margin-top: 20px;
			}
		}


		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}



.temoignages.single, .temoignage.single {
	#titre_page {
		text-align: right;
	}

	#single_temoignage {
		margin: 40px 0;

		.texte {
			
		}

		.zone_images {
			img {
				width: 100%;
				object-fit: cover;
			}

			.fullwidth_container {
				margin: 20px 0;

				img { max-height: 600px; }
			}

			.demi_container {
				display: flex;
				margin: 20px 0;

				img { max-height: 30vw; }

				.spacer {
					margin: 0 10px;
				}
			}

			.ligne {
				display: block;
				height: 1px;
				width: 75%;
				margin: auto;
				background-color: rgba($color_black, 0.5);
			}
		}

		@include media-breakpoint-down($responsive_breakpoint) {

			.texte_full {
				margin-top: 30px;
			}	
		}
	}


}