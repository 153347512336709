
.terrains, .id_12 {

	#titre_page h3 {
		display: block;
	}

	#columns {
		margin-top: 50px;
		margin-bottom: 80px;
		text-align: center;

		img {
			width: 100%;
			height: 20vw;
			object-fit: cover;

			@include media-breakpoint-down($responsive_breakpoint) { height: 50vw; }
		}

		.carte {
			margin-top: 40px;
			height: calc(200px + 15vw);
			border: 1px solid rgba($color_black, 0.3);
		}

		.texte {
			margin: 20px 0;
			text-align: left;

			p { margin: 0; }
		}

		.btn {
			margin: auto;
		}

		.video_container {
			margin-top: 142px;
			height: calc(200px + 15vw);
		}
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#columns {
			> div {
				margin-bottom: 60px;
			}

			.texte {
				padding: 0;
				margin: 16px 0;
			}
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}

// Terrain SINGLE
.terrains {
	#resume_terrains {
		margin-bottom: 60px;

		#carte_gmap {
			height: 35vw;
			margin-bottom: 30px;
		}

		.texte_container {
			h3 {
				font-size: 1.3rem;
				font-weight: 700;
			}
		}

		.plan_pdf_container {
			position: relative;
			margin-bottom: 30px;

			.image_plan {
				width: 100%;
				//max-height: 45vw;
				object-fit: cover;
			}
		
			.bouton_plan_pdf {
				position: absolute;
				bottom: 40px;
				right: 40px;
			}
		}

		.photo_terrain {
			width: 100%;
			max-height: 27vw;
			object-fit: cover;
		}
	}

	#detail_terrains {
		.bloc_terrain {
			width: 100%;
			margin-bottom: 50px;

			.titre_terrain {
				font-weight: 900;
			}

			.vignettes {
				.vignette {
					width: 100%;
					height: 20vw;
					object-fit: cover;
				}

				.boutons_container {
					margin-top: 20px;
					text-align: center;

					.btn { margin-bottom: 10px; }
					.btn:last-child { margin-bottom: 0; }
				}
			}
		}
	}
}


// Terrain V2

.terrain_v2 {
	.entete_3col {
		margin-bottom: 70px;

		> * {
			min-height: 350px;
		}

		h3 {
			font-weight: 500;
		}

		.photo_terrain_container {
			height: 100%;

			img {
				@extend %imgcover;
			}
		}

		#carte_gmap {
			height: 100%;
		}
	}

	#resume_terrains {
		.bloc_terrain {
			margin-bottom: 30px;

			img {
				width: 100%;
				margin-bottom: 20px;
			}

			.surtitre {
				font-weight: 700;
				margin: 0;
			}

			.titre_terrain {
				font-weight: 700;
				margin-bottom: 15px;
			}
		}

		.plan_pdf_container {
			.points_container {
				a {
					z-index: 2;
					position: absolute;
					height: 40px;
					width: 40px;
					background-color: $color_orange;
					border-radius: 1000px;
					box-shadow: 2px 2px 8px rgba($color_black, 0.4);
					transform: translate(-50%, -50%);
					transition: 0.3s;

					.chiffre {
						@extend %abcenter_xy;
						z-index: 1;
						color: $color_white;
						font-size: 1.5rem;
						line-height: 1;
						font-weight: 700;
						transform: translate(-50%, calc(-50% - 1px))
					}
				}

				a:hover {
					transform: translate(-50%, -50%) scale(1.1);
					box-shadow: 4px 4px 10px rgba($color_black, 0.3);
				}
			}
		}
	}
}


.terrain_souspage {
	.entete {
		margin-bottom: 50px;

		.img_principale {
			width: 100%;
		}

		.surtitre {
			color: $color_orange;
			font-weight: 700;
			margin: 0;
		}

		.titre_terrain {
			font-weight: 700;
			margin-bottom: 20px;
		}

		.desc_container {
			font-size: 1.3rem;
			line-height: 1.1;
		}
	}

	.vignettes {
		margin-bottom: 50px;

		.vignette {
			width: 100%;
			height: 20vw;
			object-fit: cover;
		}

		.boutons_container {
			margin-top: 20px;
			text-align: center;

			.btn { margin-bottom: 10px; }
			.btn:last-child { margin-bottom: 0; }

			.sinscrire {
				font-size: 1.3rem;
				font-weight: 700;
				text-decoration: none;
			}
		}
	}
}