
.presentation, .histoire, .id_20, .id_50 {

	#contenu_page {
		margin-bottom: 150px;

		.soustitre_page {
			margin-top: 50px;
			margin-bottom: 30px;
		}

		.gauche {
			padding-right: 50px;
		}

		.droite {
			padding-top: 50px;

			img {
				margin-bottom: 20px;
			}
		}
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#contenu_page {
			margin: 0;
			padding: 4vw;

			.gauche, .droite {
				padding: 0;
				width: 100%;
			}

			.soustitre_page {
				margin-top: 0;
			}


		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


