
.documentations, .id_54 {

	#titre_page h3 {
		display: block;
	}

	#contenu_page {
		margin-bottom: 140px;
		text-align: right;

		h2, h3 {
			text-align: center;
			font-size: 1.4rem;
			font-weight: 700;
			margin-top: 16px;
			margin-bottom: 20px;
		}

		img {
			width: 100%;
			height: 260px;
			object-fit: contain;
			margin-bottom: 30px;
		}

		.img_plaquette {
			height: 320px;
		}

		.btn {
			min-width: 300px;
			//position: absolute;
			//display: block;
			//margin-top: 20px;
			padding: 0.4rem 2.4rem;
			font-size: 0.9rem;
			//text-align: right;
		}

		.picto_pdf {
			display: block;
			width: 60px;
			margin: auto;
			margin-top: 30px;
		}

		.bouton_bas_container {
			margin-top: 10px;
			text-align: center;

			.btn {
				display: inline-block;
			}
		}
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#contenu_page {
			.row > div {
				margin-bottom: 60px;

				.picto_pdf {
					margin-top: 10px;
				}
			}

			.bouton_bas {
				white-space: initial;
			}
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


