
.actualites, .club-des-ambassadeurs, .parent_10, .id_31 {

	#filtres {
		margin-top: 40px;

		.titre {
			font-size: 1.2rem;
			font-weight: 700;
			margin-bottom: 6px;

			
		}

		.btn {
			outline: none;

			@include media-breakpoint-down($responsive_breakpoint) {
				font-size: 0.9rem;
				padding: 5px;
				margin-bottom: 5px;
			}

			&:focus {
				border: none;
				outline: none;
			}
			&.active {
				color: $white;
				background-color: $color_orange;
			}
		}
	}

	#liste_news {
		margin: 60px 0;

		.news_item {
			margin-bottom: 70px;

			.image {
				img {
					width: 100%;
				}
			}

			.contenu {
				padding-left: 4vw;

				.type {
					color: #999;
					font-size: 0.85rem;
				}

				.titre {
					color: $color_black !important;
					font-weight: 700;
				}

				.texte_court {

				}

				a {
					text-decoration: none;
				}
			}
		}
	}

	.support {
		color: $color_orange;
		font-weight: 700;
	}

	#single_news, #single_revue {
		margin: 40px 0;

		.texte {
			padding-left: 4vw;
		}
	}
	
	#single_revue {
		.texte_full {
			margin-top: 30px;
		}
	}

	@include media-breakpoint-down($responsive_breakpoint) {

		#liste_news {
			.news_item {

				.titre {
					margin-top: 20px;
				}
			}
		}

		#single_news, #single_revue {
			img {
				margin-bottom: 25px;
				width: 100% !important;
			}

			.texte {
				padding: 0;
			}
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


