
.nos-engagements, .qui-sommes-nous, .id_21 {

	.site_content {
		/*max-width: 1500px;*/
		margin-left: auto;
		margin-right: auto;
	}

	#entete_page {
		h2 {
			margin-bottom: 20px;
		}
	}

	.txt_centered .surtitre, .txt_centered .titre, .txt_centered .texte {
		text-align: center;
	}

	#etage_3, #etage_5 {
		.texte {
			margin-bottom: 40px;
		}
	}

	.bloc {
		position: relative;
		padding: 50px 15px;
		color: $color_black;

		.surtitre {
			font-weight: 700;
			margin-bottom: 10px;
		}

		.titre {
			font-size: 2rem;
			font-weight: 700;
			margin-bottom: 20px;
		}

		img {
			width: 100%;
		}

		.numbered_separator {
			position: absolute;
			width: 100%;
			height: 1px;
			top: 0;
			left: 0;

			.filet {
				width: 100%;
				height: 1px;
				background-color: $color_black;
			}

			.numero {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: $color_orange;
				padding: 16px;
				border-radius: 1000px;

				p {
					position: absolute;
					margin: 0;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: $color_white;
					font-size: 1.3rem;
					font-weight: 700;
				}
			}
		}
	}

	#etage_7 {
		/*.bloc {
			padding: 0;
		}

		.absolute_text {
			position: absolute;
			top: 50px;
			left: 50%;
			transform: translateX(-50%);
			color: $color_white;
		}*/
	}
	

	@include media-breakpoint-down($responsive_breakpoint) {

		#entete_page {
			margin-top: 50px;
		}

		section br {
			display: none;
		}

		section {
			.gauche, .droite {
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}

		#etage_7 .absolute_text {
			left: 10%;
			width: 80%;
			transform: none;
		}

		.site-main {
		    padding: 0;
		}

		section .content {
			width: 100% !important;
		}
		
	}

}


